import { isAuthenticated } from "@/_store/user"

/**
 * basic constraint class
 */
export default abstract class Constraint {
  /**
   * checks whether the user is authenticated
   *
   * @return        {@code true} if the user is authenticated,
   *                {@code false} otherwise
   */
  abstract isSatisfied(): boolean

  /**
   * checks whether the user is authenticated (see user store 'isAuthenticated')
   *
   * @return    truthy value if the user is authenticated, falsy otherwise
   */
  isAuthenticated(): boolean {
    return isAuthenticated()
  }
}
