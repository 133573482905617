import { fetchData, FetchDataResult, FetchOperation } from "./http"

import { MailBackendDTO, GetMailBackendsResponse } from "./api-docs"

export type MailBackend = MailBackendDTO

/**
 * HTTP API operations (as URL path suffixes), which can be performed by the
 * service
 */
const operations: Record<string, FetchOperation> = {
  getBackendList: { method: "get", url: "mail-backend/all" },
}

/**
 * request the list of clients with the client id and name
 */
export async function getMailBackends(): Promise<
  FetchDataResult<Array<MailBackend>>
> {
  return await fetchData<
    undefined,
    Array<MailBackend>,
    GetMailBackendsResponse
  >(operations.getBackendList, undefined, (responseData) => {
    responseData.sort((a, b) => (a?.name || "").localeCompare(b?.name || ""))
  })
}
