import VueI18n, { LocaleMessages } from "vue-i18n"
import { inject, provide, ref } from "@vue/composition-api"
import { createI18n, LS_I18N_LOCALE_KEY } from "./createI18n"

import type { I18n, I18nProvide, RequireContext, I18n_T } from "./types"

// internally used as a `key` for provide/inject functions
const i18nSymbol = Symbol("i18n")

// locales, supported by the application
export const availableLocales: Array<string> = ["en", "de"]

const defaultLocale = availableLocales[0]

/**
 * get the locale from browser
 */
function getNavigatorLocale(): string | undefined {
  const lang =
    (navigator.languages &&
      navigator.languages.length &&
      navigator.languages[0]) ||
    navigator.language

  // `lang` may be "de-DE" or "en-US", thus get first 2 letters
  return lang && lang.substr(0, 2)
}

/**
 * get locale according to browser language or the first available
 */
function getGuessedLocale(): string {
  const lang = getNavigatorLocale()
  return lang && availableLocales.includes(lang) ? lang : availableLocales[0]
}

/**
 * Load i18n messages from given message files. Normally the message files are
 * returned by webpack's `require.context` function. The file name suffix
 * like "de" in "general.de.yaml" will be used to define the message locale.
 *
 * @param messageFiles    files to be used for i18n messages
 */
const loadMessages = (messageFiles: RequireContext): LocaleMessages => {
  return messageFiles.keys().reduce((messages, fileName) => {
    const locale = fileName.replace(/\.?\/?.*?([^.]+)\.\w+$/i, "$1")
    messages[locale] = Object.assign(
      messages[locale] || ({} as LocaleMessages),
      messageFiles(fileName)
    )
    return messages
  }, {} as LocaleMessages)
}

/**
 * The function tries to inject the i18n configuration from application scope,
 * if no configuration is available, the the default one will be provided and
 * saved to application scope.
 */
export const useI18nProvide = (): I18nProvide => {
  const injected = inject<I18nProvide>(i18nSymbol, {
    sharedMessages: loadMessages(
      require.context("@/_i18n/message", true, /[\w-]+\.(json|ya?ml)$/i)
    ),
    locale: ref(""),
  })

  // if nothing was provided before, the `injected` contains defaults
  if (injected.locale.value === "") {
    injected.locale.value =
      localStorage.getItem(LS_I18N_LOCALE_KEY) || getGuessedLocale()
    provide<I18nProvide>(i18nSymbol, injected)
  }

  // here it was surely already provided
  return injected
}

/**
 * function to be used in composition API `setup` method to be able to deal with i18n
 *
 * @param messageFiles            files to be additionally used for i18n messages
 *                                (with higher priority)
 * @param excludeSharedMessages   if set to {@code true}, then shared messages will be ignored.
 *                                This parameter should be used for components, which does not
 *                                reference messages from general i18n files.
 */
export const useI18n = (
  messageFiles?: RequireContext,
  excludeSharedMessages = false
): I18n => {
  const { sharedMessages, locale } = useI18nProvide()

  const messages = messageFiles
    ? loadMessages(messageFiles)
    : !excludeSharedMessages
    ? sharedMessages
    : undefined

  return createI18n(locale, {
    locale: locale.value,
    fallbackLocale: defaultLocale,
    messages,
    // prevent unneeded message merge if no special message files was provided
    sharedMessages:
      excludeSharedMessages || !messageFiles ? undefined : sharedMessages,
  })()
}

/**
 * Provides the function, which can be used for global i18n location change
 */
export const useChangeLocale = (): ((newLocale?: string) => void) => {
  const { locale } = useI18nProvide()
  return (newLocale?: string): void => {
    if (locale) {
      locale.value =
        newLocale &&
        Object.prototype.toString.call(newLocale) === "[object String]" &&
        availableLocales.includes(newLocale)
          ? newLocale
          : locale.value === availableLocales[0]
          ? availableLocales[1]
          : availableLocales[0]
    }
  }
}

/**
 * Try to translate with the given parameter if it could not be translated just
 * return the key
 *
 * @param t         the translator function
 * @param prefix    the prefix to use
 * @param key       the key of the path
 * @param params    the optional parameter to use
 */

export const tryTranslation = (
  t: I18n_T,
  prefix: string,
  key: string,
  params?: VueI18n.Values
): string => {
  const path = `${prefix}${key}`
  const translated = t(path, params) as string
  return path === translated ? key : translated
}
