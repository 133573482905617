var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"text":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-translate")]),_vm._v(" "+_vm._s(_vm.t(("locales." + _vm.locale)))+" "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("mdi-menu-down")])],1)]}}],null,true)},[_c('span',[_vm._v("Language")])])]}}])},[_c('v-list',_vm._l((_vm.availableLocales),function(loc,index){return _c('v-list-item',{key:index,class:{
        'v-list-item--active': _vm.isLocaleActive(loc),
      },on:{"click":function($event){return _vm.changeLocale(loc)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.t(("locales." + loc))))])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }