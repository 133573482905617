import Constraint from "./Constraint"

class NotAuthenticatedConstraint extends Constraint {
  /**
   * checks whether the user is not authenticated
   *
   * @return        {@code true} if the user is not authenticated,
   *                {@ false} otherwise
   */
  isSatisfied(): boolean {
    return !this.isAuthenticated()
  }
}

/**
 * the constraint, checking if the user is not authenticated
 */
export default new NotAuthenticatedConstraint()
