import Vue from "vue"
import { User } from "@/_service/user"

/**
 * data, stored for the user
 */
interface UserState {
  jwt?: string
  user?: User
}

/**
 * payload data from JWT
 */
interface JwtPayload {
  sub: string
  nbf: number
  exp: number
}

/**
 * local storage key to be used to store the current user JWT
 */
const JWT_KEY = "jwt"

/**
 * local storage key to be used to store the current user data
 */
const USER_KEY = "user"

/**
 * extract the payload from given JWT string
 *
 * @param jwt   JWT string
 */
function jwtPayload(jwt: string): JwtPayload | undefined {
  return JSON.parse(atob(jwt.split(".")[1])) || undefined
}

/**
 * checks whether the given JWT string is still valid (expiration time)
 *
 * @param jwt   JWT string
 */
function jwtValid(jwt: string): boolean {
  const payload = jwtPayload(jwt)
  let valid = false
  if (payload?.exp) {
    const secsFromEpoch = new Date().getTime() / 1000
    valid = payload.exp > secsFromEpoch
  }
  return valid
}

/**
 * create the initial user state object, checking local storage for saved data
 */
function getInitialUserState(): UserState {
  let jwt = localStorage.getItem(JWT_KEY) || undefined
  let user

  if (jwt && jwtValid(jwt)) {
    user = JSON.parse(localStorage.getItem(USER_KEY) || "null") || undefined
    if (user === undefined) {
      jwt = undefined
    }
  } else {
    jwt = undefined
  }

  return {
    jwt,
    user,
  }
}

/**
 * the reactive object, containing user data
 */
const state = Vue.observable<UserState>(getInitialUserState())

export function clear(): void {
  setJwt("")
  setUser(undefined)
}

export function getJwt(): string | undefined {
  return state?.jwt
}

export function getJwtPayload(): JwtPayload | undefined {
  const jwt = state?.jwt

  return (jwt && jwtPayload(jwt)) || undefined
}

export function isAuthenticated(): boolean {
  return !!state?.jwt
}

export function setJwt(newJwt: string): void {
  state.jwt = newJwt
  localStorage.setItem(JWT_KEY, newJwt)
}

export function getUser(): User | undefined {
  return state.user
}

export function setUser(newUser: User | undefined): void {
  state.user = newUser
  localStorage.setItem(USER_KEY, newUser ? JSON.stringify(newUser) : "")
}

export function getName(): string | undefined {
  return state?.user?.userName
}
