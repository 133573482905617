import { getClients } from "@/_store/client"

import { fetchData, FetchDataResult, FetchOperation } from "./http"

import {
  ClientCreateDTO,
  ClientDTO,
  CreateClientResponse,
  DeleteClientQuery,
  DeleteClientResponse,
  ErrorResponseDTO,
  GetClientQuery,
  GetClientResponse,
  GetSimpleClientListResponse,
  GetStatisticQuery,
  GetStatisticResponse,
  ResponseDTO,
  StatisticDTO,
} from "./api-docs"

export type Client = ClientDTO
export type ClientRequest = GetClientQuery
export type StatisticRequest = GetStatisticQuery
export type StatisticResponse = GetStatisticResponse
export type Statistic = StatisticDTO

/**
 * HTTP API operations (as URL path suffixes), which can be performed by the
 * service
 */
export const operations: Record<string, FetchOperation> = {
  getClientList: { method: "get", url: "clients/all" },
  createClient: { method: "post", url: "clients/create", isJson: true },
  deleteClient: { method: "delete", url: "clients/delete" },
  getStatistics: { method: "get", url: "clients/statistic" },
  getClient: { method: "get", url: "clients/read" },
  updateClient: { method: "put", url: "clients/update", isJson: true },
  downloadMailboxes: { method: "get", url: "clients/download-mailboxes" },
  downloadDomains: { method: "get", url: "clients/download-domains" },
  downloadAliases: { method: "get", url: "clients/download-aliases" },
}

/**
 * request the list of clients with the client id and name
 */
export async function getClientList(): Promise<FetchDataResult<Array<Client>>> {
  return await fetchData<undefined, Array<Client>, GetSimpleClientListResponse>(
    operations.getClientList,
    undefined,
    (responseData) => {
      responseData.sort((a, b) => a.name.localeCompare(b.name))
    }
  )
}

/**
 * Create a new client with the given params
 *
 * @param params the parameter for the client
 */
export async function createClient(
  params: ClientCreateDTO
): Promise<FetchDataResult<ResponseDTO>> {
  return await fetchData<ClientCreateDTO, ResponseDTO, CreateClientResponse>(
    operations.createClient,
    params
  )
}

/**
 * type of data to be used by backend to update the client
 */
export type ClientUpdateRequest = Client

/**
 * update client
 *
 * @param data      data, provided to perform the operation
 */
export async function updateClient(
  data: ClientUpdateRequest
): Promise<FetchDataResult<ResponseDTO>> {
  return await fetchData<ClientUpdateRequest, ResponseDTO, ErrorResponseDTO>(
    operations.updateClient,
    data,
    () => {
      // refreshCurrentClientData()
    }
  )
}

/**
 * delete a client with the given id
 *
 * @param params the parameter for the client
 */
export async function deleteClient(
  params: DeleteClientQuery
): Promise<FetchDataResult<DeleteClientResponse>> {
  return await fetchData<
    DeleteClientQuery,
    DeleteClientResponse,
    ErrorResponseDTO
  >(operations.deleteClient, params)
}

/**
 * Get the statistics for a client
 *
 * @param params the parameter containing the client id
 */
export async function getStatistics(
  params: StatisticRequest
): Promise<FetchDataResult<StatisticResponse>> {
  return await fetchData<StatisticRequest, StatisticResponse, ErrorResponseDTO>(
    operations.getStatistics,
    params
  )
}

/**
 * Get a client by its id
 *
 * @param params the parameter containing the client id
 */
export async function getClient(
  params: GetClientQuery
): Promise<FetchDataResult<GetClientResponse>> {
  // search the client in the cache (store)
  const client = getClients()?.find((it) => it.id === params.clientId)

  return client
    ? Promise.resolve({
        success: true,
        data: client,
      })
    : await fetchData<GetClientQuery, GetClientResponse, ErrorResponseDTO>(
        operations.getClient,
        params
      )
}

/**
 * create default client data object
 */
export function createDefaultClientData(): Client {
  return {
    id: undefined,
    name: "",
    backendId: undefined,
  }
}
