import Vue from "vue"
import Vuetify from "vuetify/lib"

import de from "vuetify/src/locale/de"
import en from "vuetify/src/locale/en"

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { de, en },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#1cb8d4",
        secondary: "#94bf0a",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
})
