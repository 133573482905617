









































import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch,
} from "@vue/composition-api"
import { useI18n } from "@/_i18n"

import { findActiveTabIndex, NavItem, routeToNavItem } from "./AppMenu"

// the proper Vuetify type/interface should be used instead as soon as it is available (Vuetify 3?)
interface Resizable {
  onResize: () => void
}

export default defineComponent({
  name: "AppMenu",

  setup(_, { root }) {
    const { t, locale } = useI18n()
    const tab = ref<string | number>("")
    const tabs = reactive(new Array<string | number>())

    const routes = root.$router.options.routes
    const nav = computed<Array<NavItem>>(() => {
      const navItems: Array<NavItem> = []

      routes?.forEach((route) => {
        const navItem = routeToNavItem(route)
        if (navItem) {
          navItems.push(navItem)
        }
      })

      return navItems
    })

    // this is a workaround for activation of the current tab/sub-tab on page (re)load
    const currentRoute = computed(() => root.$route)
    watch(currentRoute, () => {
      const path = currentRoute.value.path

      // look for the item with longest path, which at start matches the current route path
      tab.value = findActiveTabIndex(nav.value, path)

      // process sub-menus
      tabs.forEach((_, idx) => {
        const navItems = nav.value[idx]?.children
        if (Array.isArray(navItems)) {
          root.$set(tabs, idx, findActiveTabIndex(navItems, path))
        }
      })
    })

    // change size of active tab indicator on language change.
    // see https://github.com/vuetifyjs/vuetify/issues/4733
    const primaryTabs = ref()
    const secondaryTabs = ref<Array<Resizable>>()

    watch(locale, () => {
      primaryTabs.value?.onResize()
      secondaryTabs.value?.forEach((it) => it?.onResize())
    })

    return {
      nav,
      primaryTabs,
      secondaryTabs,
      tabs,
      t,
      tab,
    }
  },
})
