import Vue, { CreateElement } from "vue"
// TODO, FIXME: remove after upgrade to Vue3
import VueCompositionAPI, {
  createApp,
  SetupContext,
  watch,
} from "@vue/composition-api"
Vue.use(VueCompositionAPI)

import App from "@/App.vue"
import vuetify from "@/plugin/vuetify"
import router from "@/router"
import { useI18n } from "@/_i18n"

import "roboto-fontface/css/roboto/roboto-fontface.css"
import "@mdi/font/css/materialdesignicons.css"

Vue.config.productionTip = false

createApp({
  router,
  vuetify,
  render: (h: CreateElement) => h(App),
  setup(_: unknown, ctx: SetupContext) {
    // _i18n need to be initialized on the top component, otherwise
    // the locale change does not work
    const { locale } = useI18n()

    // switch Vuetify locale if global (i18n) locale changes
    watch(
      locale,
      () => {
        ctx.root.$vuetify.lang.current = locale.value
      },
      { immediate: true }
    )
  },
}).mount("#app")
