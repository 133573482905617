import { getMailBackends, MailBackend } from "@/_service/mailBackend"
import { createCacheStore } from "@/_store/storeHelper"

const store = createCacheStore<MailBackend>(getMailBackends)

export const clear = store.clear
export const getBackends = store.getCached
export const setBackends = store.setCached
export const loadBackends = store.loadCached
export const smartGetBackends = store.smartGetCached
export const useBackends = store.useCached
