





















import { defineComponent } from "@vue/composition-api"
import { useI18n } from "@/_i18n"

import AppMenu from "./AppMenu.vue"
import LocaleControl from "./LocaleControl.vue"
import UserInfo from "./UserInfo.vue"

export default defineComponent({
  name: "AppHeader",

  components: { AppMenu, LocaleControl, UserInfo },

  setup() {
    const { t } = useI18n()
    return {
      t,
    }
  },
})
