import { getUser, isAuthenticated } from "@/_store/user"

import { PermissionMap } from "@/_service/user"

import Constraint from "./Constraint"

class PermissionConstraint extends Constraint {
  /** the permissions needed */

  neededPermissions: PermissionMap

  /**
   * constructor
   *
   * @param neededPermissions needed permission
   */

  constructor(neededPermissions: PermissionMap) {
    super()
    this.neededPermissions = neededPermissions
  }

  /**
   * checks whether the user is authenticated
   *
   * @return        {@code true} if the user is authenticated,
   *                {@code false} otherwise
   */
  isSatisfied(): boolean {
    const currentPermissions = this.getPermissions()
    const neededPermissionKeys = Object.keys(this.neededPermissions) as Array<
      keyof PermissionMap
    >

    if (neededPermissionKeys.length === 0) return true
    if (!currentPermissions) return false

    return neededPermissionKeys.every((permissionKey) => {
      return (
        currentPermissions[permissionKey] ===
        this.neededPermissions[permissionKey]
      )
    })
  }

  /**
   * Get the permissions of the currently logged in user.
   * may be {@code undefined} if the user is not authenticated
   */

  getPermissions(): PermissionMap | undefined {
    let permissions

    if (isAuthenticated()) {
      permissions = getUser()?.permissions
    }

    return permissions
  }
}

/**
 * the permission constraint class
 */
export default PermissionConstraint
