





















































import { defineComponent, computed } from "@vue/composition-api"
import { useI18n } from "@/_i18n"
import { getName, isAuthenticated } from "@/_store/user"
import { logout } from "@/_service/user"

export default defineComponent({
  name: "UserInfo",

  setup() {
    const { t } = useI18n(
      require.context("./", true, /UserInfo\.[\w-]+\.(json|ya?ml)$/i)
    )
    const name = computed(getName)
    const authenticated = computed(isAuthenticated)

    return {
      t,
      name,
      authenticated,
      logout: () => {
        logout()
      },
    }
  },
})
