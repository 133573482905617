import Constraint from "./Constraint"

class AuthenticatedConstraint extends Constraint {
  /**
   * checks whether the user is authenticated
   *
   * @return        {@code true} if the user is authenticated,
   *                {@code false} otherwise
   */
  isSatisfied(): boolean {
    return this.isAuthenticated()
  }
}

/**
 * the constraint, checking if the user is authenticated
 */
export default new AuthenticatedConstraint()
