import Vue from "vue"
import { ref, Ref } from "@vue/composition-api"
import { FetchDataResult } from "@/_service/http"

export type UseCached<T> = {
  isLoading: Ref<boolean>
  cached: Ref<Array<T> | undefined>
  refresh: () => Promise<void>
}

export function createCacheStore<T>(
  loadFunc: () => Promise<FetchDataResult<Array<T>>>
): {
  clear: () => void
  getCached: () => Array<T> | undefined
  setCached: (cached: Array<T>) => void
  loadCached: () => Promise<void>
  smartGetCached: () => Promise<Array<T> | undefined>
  useCached: (skipInitialLoad?: boolean) => UseCached<T>
} {
  /**
   * the cached backends data
   */
  interface State {
    cached?: Array<T>
  }

  /**
   * the reactive object, cached backends data
   */
  const state = Vue.observable<State>({
    cached: undefined,
  })

  function clear(): void {
    setCached(undefined)
  }

  function getCached(): Array<T> | undefined {
    return state.cached
  }

  function setCached(cached: Array<T> | undefined): void {
    state.cached = cached
  }

  /**
   * load available backends from backend and store them
   */
  async function loadCached(): Promise<void> {
    let backends
    const fetchDataResult = await loadFunc()

    if (fetchDataResult.success) {
      backends = fetchDataResult.data as Array<T>
    }

    setCached(backends)
  }

  /**
   * if backends are already available in store, then return them, otherwise,
   * load them from the rest endpoint.
   */
  async function smartGetCached(): Promise<Array<T> | undefined> {
    if (!Array.isArray(state.cached)) {
      await loadCached()
    }
    return getCached()
  }

  const useCached = (skipInitialLoad = false): UseCached<T> => {
    const isLoading = ref(false)
    const cached = ref(undefined) as Ref<Array<T> | undefined>

    const getInitial = async () => {
      isLoading.value = true
      cached.value = await smartGetCached()
      isLoading.value = false
    }

    // use to make it not an async function
    if (!skipInitialLoad) getInitial()

    const refresh = async () => {
      isLoading.value = true
      await loadCached()
      cached.value = getCached()
      isLoading.value = false
    }

    return {
      cached,
      isLoading,
      refresh,
    }
  }

  return {
    clear,
    getCached,
    setCached,
    loadCached,
    smartGetCached,
    useCached,
  }
}
