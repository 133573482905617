import { RouteConfig } from "vue-router"
import Constraint from "@/_permission/Constraint"

/**
 * route metadata, used by menu
 */
interface RouteMeta {
  label: string
  constraint: Constraint
  hideInMenu: boolean
}

/**
 * navigation item (menu item)
 */
export interface NavItem {
  name?: string
  path?: string
  label: string
  constraint?: Constraint
  children?: Array<NavItem>
}

/**
 * checks whether the specified route is accessible by current user
 *
 * @param route       route to check
 */
function isMenuAccessible(route: RouteMeta) {
  const constraint = route.constraint

  return constraint ? constraint.isSatisfied() : true // all menus are accessible by default
}

/**
 * convert given route to navigation item
 *
 * @param route     route to convert
 */
export function routeToNavItem(route: RouteConfig): NavItem | undefined {
  let navItem: NavItem | undefined

  if (route.meta && !route.meta.hideInMenu && isMenuAccessible(route.meta)) {
    navItem = {
      name: route.name,
      path: route.path,
      label: route.meta.label,
      constraint: route.meta.constraint,
    }

    if (route.children) {
      const children: Array<NavItem> = []
      route.children.forEach((child) => {
        const c = routeToNavItem(child)
        if (c) {
          children.push(c)
        }
      })

      if (children.length) {
        navItem.children = children
      } else {
        // if the menu item has children, but they all are not accessible,
        // then this item should not be displayed
        navItem = undefined
      }
    }
  }

  return navItem
}

/**
 * creates the reducer, which looked for active menu item index
 *
 * @param path    current route path, used to find the active item
 */
function indexForPathReducer(path: string) {
  return (accu: Array<number>, it: NavItem, idx: number) => {
    const curPath = it.path
    if (curPath && path.startsWith(curPath)) {
      const curLen = curPath.length
      const maxLen = accu[1]
      if (curLen > maxLen) {
        accu[0] = idx
        accu[1] = curLen
      }
    }
    return accu
  }
}

/**
 * find index of active item inside given array for given path
 *
 * @param navItems    items to look up
 * @param path        current path to be used to define the active item
 */
export function findActiveTabIndex(
  navItems: Array<NavItem>,
  path: string
): number {
  return navItems.reduce(indexForPathReducer(path), [-1, -1])[0]
}
