


























import { defineComponent, computed } from "@vue/composition-api"

import AppHeader from "@/component/AppHeader.vue"
import UserInfo from "@/component/UserInfo.vue"
import AppFooter from "@/component/AppFooter.vue"
import DefaultLayout from "@/component/Layout/DefaultLayout.vue"
import { useI18n } from "@/_i18n"
import { getRouteTitle, getRouteTitleKey } from "@/_store/routing"

export default defineComponent({
  name: "App",

  components: {
    AppFooter,
    AppHeader,
    UserInfo,
  },

  setup(_, { root }) {
    const { t } = useI18n()

    const layout = computed(() => {
      return root.$route.meta.layout || DefaultLayout
    })

    const title = computed(() => {
      return (
        getRouteTitle() ||
        t(getRouteTitleKey() || root.$route.meta.label || "app_name")
      )
    })

    return { layout, title }
  },
})
