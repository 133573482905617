import Vue from "vue"
import VueRouter, { Route, RouteConfig, RouterMode } from "vue-router"
import { Dictionary } from "vue-router/types/router"
import { computed, Ref } from "@vue/composition-api"

import Configuration from "@/configuration"

import {
  AccountingConstraint,
  AdminConstraint,
  AuthenticatedConstraint,
  NotAuthenticatedConstraint,
} from "@/_permission"

import {
  setDesiredRoute,
  setRouteTitle,
  setRouteTitleKey,
} from "@/_store/routing"

import { isAuthenticated } from "@/_store/user"

import CenteredLayout from "@/component/Layout/CenteredLayout.vue"
import RouteContainer from "@/component/RouteContainer.vue"

Vue.use(VueRouter)

export const DOWNLOAD_BASE_PATH = "/download"

/**
 * creates link to FE download page
 *
 * @param path        BE endpoint path
 * @param params      BE endpoint parameters
 * @return            `Location` object to be used by router links
 *
 * Note:  the correct return type of the reference should be `Location`
 *        instead of `Record`, but the type definition of the `params´ property
 *        (`Dictionary<string>`) does not yet allow a param of type `Array` as
 *        described here:
 *        https://next.router.vuejs.org/guide/essentials/route-matching-syntax.html#repeatable-params
 *
 * TODO:  for the next `router` version the `Record` need to be replaced by
 *       `Location`
 */
export function useDownloadLink(
  path: string,
  params: Record<string, unknown>
): Ref</*Location*/ Record<string, unknown>> {
  return computed(() => {
    const query = Object.keys(params).reduce((acc, k) => {
      if (params[k]) acc[k] = `${params[k]}`

      return acc
    }, {} as Dictionary<string>)

    return {
      name: "download",
      params: {
        path: path.split("/"),
      },
      query,
    }
  })
}

/**
 * object, describing BE API endpoint
 */
export interface DownloadProps {
  /** BE API endpoint path */
  path: string
  /** BE API endpoint parameters */
  query: Dictionary<string | Array<string | null>>
}

/**
 * prepare properties for download page
 *
 * @param route     route to be processed
 */
export function downloadPropsFromRoute(route: Route): DownloadProps {
  const path = Array.isArray(route.params.path)
    ? route.params.path.join("/")
    : route.params.path
  const query = route.query

  return {
    path,
    query,
  }
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "dashboard",
    meta: {
      label: "menu.dashboard",
      layout: CenteredLayout,
      constraint: AuthenticatedConstraint,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "@/view/Dashboard/Dashboard.vue"
      ),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      label: "menu.login",
      constraint: NotAuthenticatedConstraint,
      layout: CenteredLayout,
      denied: "dashboard",
      hideInMenu: true,
      transient: true,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "@/view/Login/Login.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      label: "menu.profile",
      constraint: AuthenticatedConstraint,
      layout: CenteredLayout,
      hideInMenu: true,
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/view/Profile/Profile.vue"),
  },
  {
    path: "/client",
    name: "client",
    meta: {
      label: "menu.client.index",
      constraint: AdminConstraint,
    },
    component: RouteContainer,
    children: [
      {
        path: "/client/list",
        name: "client.list",
        meta: {
          label: "menu.client.list",
          constraint: AdminConstraint,
          layout: CenteredLayout,
        },
        component: () =>
          import(
            /* webpackChunkName: "clientList" */ "@/view/Client/List/ClientList.vue"
          ),
      },
      {
        path: "/client/create",
        name: "client.create",
        meta: {
          label: "menu.client.create",
          constraint: AdminConstraint,
          layout: CenteredLayout,
        },
        component: () =>
          import(
            /* webpackChunkName: "clientCreate" */ "@/view/Client/Create/ClientCreate.vue"
          ),
      },
      {
        path: "/client/update/:id",
        name: "client.update",
        meta: {
          constraint: AdminConstraint,
          layout: CenteredLayout,
          hideInMenu: true,
        },
        props: (route) => {
          const id = Number.parseInt(route.params.id, 10)
          return { id: Number.isNaN(id) ? -1 : id }
        },
        component: () =>
          import(
            /* webpackChunkName: "clientUpdate" */ "@/view/Client/Update/ClientUpdate.vue"
          ),
      },
    ],
  },
  {
    path: "/user",
    name: "user",
    meta: {
      label: "menu.user.index",
      constraint: AdminConstraint,
    },
    component: RouteContainer,
    children: [
      {
        path: "/user/list",
        name: "user.list",
        meta: {
          label: "menu.user.list",
          constraint: AdminConstraint,
          layout: CenteredLayout,
        },
        component: () =>
          import(
            /* webpackChunkName: "userList" */ "@/view/User/List/UserList.vue"
          ),
      },
      {
        path: "/user/create",
        name: "user.create",
        meta: {
          label: "menu.user.create",
          constraint: AdminConstraint,
          layout: CenteredLayout,
        },
        component: () =>
          import(
            /* webpackChunkName: "userCreate" */ "@/view/User/Create/UserCreate.vue"
          ),
      },
      {
        path: "/user/update/:id",
        name: "user.update",
        meta: {
          constraint: AdminConstraint,
          layout: CenteredLayout,
          hideInMenu: true,
        },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "userUpdate" */ "@/view/User/Update/UserUpdate.vue"
          ),
      },
    ],
  },
  {
    path: "/billing",
    name: "billing",
    meta: {
      label: "menu.billing.index",
      constraint: AccountingConstraint,
    },
    component: RouteContainer,
    children: [
      {
        path: "/billing/overview",
        name: "billing.overview",
        meta: {
          label: "menu.billing.overview",
          constraint: AccountingConstraint,
          layout: CenteredLayout,
        },
        component: () =>
          import(
            /* webpackChunkName: "billingOverview" */ "@/view/Billing/Overview/BillingOverview.vue"
          ),
      },
    ],
  },
  {
    path: `${DOWNLOAD_BASE_PATH}/:path+`,
    name: "download",
    meta: {
      hideInMenu: true,
      constraint: AuthenticatedConstraint,
    },
    component: () =>
      import(/* webpackChunkName: "download" */ "@/view/Download.vue"),
    props: downloadPropsFromRoute,
  },
  {
    path: "/500",
    name: "error500",
    meta: {
      hideInMenu: true,
      constraint: AuthenticatedConstraint,
      transient: true,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "@/view/Error/500.vue"),
  },
  {
    path: "*",
    name: "error404",
    meta: {
      hideInMenu: true,
      transient: true,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "@/view/Error/404.vue"),
  },
]

const router = new VueRouter({
  base: Configuration.value("baseUrl") || "",
  mode: (Configuration.value("routerMode") as RouterMode) || "history",
  routes,
})

router.beforeEach((to, from, next) => {
  // route is denied if some constraint of matched routes is not satisfied
  const routeDenied = to.matched.some((routeRecord) => {
    return (
      routeRecord.meta.constraint && !routeRecord.meta.constraint.isSatisfied()
    )
  })

  const titleKey = to.meta?.label || "app_name"

  if (routeDenied) {
    next({
      name: to.meta?.denied || (isAuthenticated() ? "error500" : "login"),
    })

    // save desired route if it's not explicitly market as `transient`
    if (!to.meta?.transient) {
      const { name, hash, query, params, redirectedFrom } = to
      setDesiredRoute({
        name: name || undefined,
        hash,
        query,
        params,
        redirectedFrom,
        titleKey,
      })
    }
  } else {
    setRouteTitleKey(titleKey)
    setRouteTitle("")
    next()
  }
})

export default router
