

































import { defineComponent } from "@vue/composition-api"
import { availableLocales, useI18n, useChangeLocale } from "@/_i18n"

export default defineComponent({
  name: "LocaleSwitcher",

  setup() {
    const { locale, t } = useI18n(
      require.context("./", true, /LocaleControl\.[\w-]+\.(json|ya?ml)$/i),
      true
    )

    return {
      availableLocales,
      locale,
      t,
      isLocaleActive: (loc: string): boolean => {
        return locale.value === loc
      },
      changeLocale: useChangeLocale(),
    }
  },
})
