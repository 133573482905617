import Vue from "vue"
import { Dictionary } from "vue-router/types/router"

export interface DesiredRoute {
  name?: string | undefined
  hash: string
  query: Dictionary<string | (string | null)[]>
  params: Dictionary<string>
  redirectedFrom?: string
  // to be able to present the route by title
  titleKey: string
}

/**
 * data, stored for the routing
 */
interface RoutingState {
  /**
   * the route to be activated after "transient" view
   */
  desiredRoute?: DesiredRoute
  /**
   * the message key to be used for the route title
   */
  routeTitleKey?: string
  /**
   * the text to be used for the route title
   */
  routeTitle?: string
}

/**
 * the reactive object, containing routing data
 */
const state = Vue.observable<RoutingState>({
  desiredRoute: undefined,
  routeTitleKey: undefined,
  routeTitle: undefined,
})

export function getDesiredRoute(): DesiredRoute | undefined {
  return state.desiredRoute
}

export function setDesiredRoute(newDesiredRoute: DesiredRoute): void {
  state.desiredRoute = newDesiredRoute
}

export function clearDesiredRoute(): void {
  state.desiredRoute = undefined
}

export function getRouteTitleKey(): string | undefined {
  return state.routeTitleKey
}

export function setRouteTitleKey(newRouteTitleKey: string): void {
  state.routeTitleKey = newRouteTitleKey
}

export function getRouteTitle(): string | undefined {
  return state.routeTitle
}

export function setRouteTitle(newRouteTitle: string): void {
  state.routeTitle = newRouteTitle
}
