import router from "@/router"

import {
  FetchCallback,
  fetchData,
  FetchDataResult,
  FetchOperation,
} from "./http"

import {
  CreateUserBody,
  DeleteUserQuery,
  ErrorResponseDTO,
  GetUserQuery,
  IdentityResponseString,
  ListUsersQuery,
  ListUsersResponse,
  Permissions,
  ResponseDTO,
  UpdateProfileQuery,
  UpdateProfileResponse,
  UpdateUserBody,
  UserDTO,
} from "./api-docs"

export type CreateUserRequest = CreateUserBody
export type DeleteUserRequest = DeleteUserQuery
export type DeleteUserResponse = IdentityResponseString
export type GetUserRequest = GetUserQuery
export type ListUsersRequest = ListUsersQuery
export type PermissionMap = Permissions
export type UpdateProfileRequest = UpdateProfileQuery
export type UpdateUserRequest = UpdateUserBody
export type User = UserDTO

import { setJwt, setUser, clear as clearUserStore } from "@/_store/user"
import { clear as clearClientStore } from "@/_store/client"
import { clear as clearBackendStore } from "@/_store/backend"
import { clearDesiredRoute, getDesiredRoute } from "@/_store/routing"

/**
 * HTTP API operations (as URL path suffixes), which can be performed by the
 * service
 */
const operations: Record<string, FetchOperation> = {
  login: { method: "post", url: "login", isJson: true },
  logout: { method: "post", url: "logout", isJson: true },
  createUser: { method: "post", url: "user/create", isJson: true },
  getUser: { method: "get", url: "user/read" },
  listUsers: { method: "get", url: "user/list" },
  updateProfile: { method: "put", url: "user/updateProfile" },
  updateUser: { method: "put", url: "user/update", isJson: true },
  deleteUser: { method: "delete", url: "user/delete" },
}

/**
 * authorization header prefix
 */
const BEARER = "Bearer"

/**
 * data, needed by backend to process the login request
 */
interface LoginRequest {
  username: string
  password: string
}

export interface LoginData {
  username: string
  password: string
}

export type LoginResult = FetchDataResult<undefined>

/**
 * perform the login
 *
 * @param data          user login data (user name and password)
 * @return              {@code true} if the user was successfully authenticated,
 *                      {@code false} otherwise
 */
export async function login(data: LoginData): Promise<LoginResult> {
  const loginResult = await fetchData<LoginRequest, undefined, ResponseDTO>(
    operations.login,
    { username: data.username, password: data.password },
    processLoginResponse
  )

  const success = loginResult.success

  if (success) {
    await refreshCurrentUserData()

    redirectAfterLogin()
  }

  return loginResult
}

export async function logout(): Promise<void> {
  clearUserStore()
  clearClientStore()
  clearBackendStore()

  fetchData<undefined, undefined, ResponseDTO>(
    operations.logout,
    undefined,
    processLoginResponse
  )
  router.push({ name: "login" })
}

/**
 * business logic for successful login
 *
 * @param _           not used (is needed to satisfy the contract)
 * @param response    full login response, used to be able to read the HTTP
 *                    headers
 */
const processLoginResponse: FetchCallback<unknown> = (_, response) => {
  const jwt = jwtFromAuthorizationHeader(response?.headers["authorization"])

  if (jwt) {
    setJwt(jwt)
  }
}

/**
 * extract JWT from given authorization header
 *
 * @param authorizationHeader     header, containing JWT
 */
export function jwtFromAuthorizationHeader(
  authorizationHeader: string | undefined
): string | undefined {
  return authorizationHeader?.indexOf(BEARER) === 0
    ? authorizationHeader.substring(BEARER.length).trim()
    : undefined
}

/**
 * create authorization header for given JWT
 *
 * @param jwt       JWT, to be used in authorization header
 */
export function authorizationHeaderFromJwt(
  jwt: string | undefined
): string | undefined {
  return jwt ? `${BEARER} ${jwt}` : undefined
}

/**
 * redirect the user to `dashboard` or to the saved `desiredRoute`
 */
function redirectAfterLogin() {
  let location
  const desiredRoute = getDesiredRoute()

  if (desiredRoute) {
    const { name, hash, params, query } = desiredRoute
    location = { name, hash, params, query }
    clearDesiredRoute()
  } else {
    location = { name: "dashboard" }
  }

  router.replace(location)
}

/**
 * request current user data from backend and save them to store
 */
async function refreshCurrentUserData() {
  const data = await loadUser()

  if (data.success) {
    setUser(data.data as User)
  }
}

/**
 * request user data from backend; if user is not specified, then the data of
 * current user will be requested
 *
 * @param getUserQuery        the object, containing user name, which data need to be retrieved
 */
export async function loadUser(
  getUserQuery?: GetUserRequest
): Promise<FetchDataResult<User>> {
  return await fetchData<GetUserRequest, User, ResponseDTO>(
    operations.getUser,
    getUserQuery
  )
}

/**
 * the result of the user "updateProfile" operation
 */
export type UpdateProfileResult = FetchDataResult<UpdateProfileResponse>

/**
 * update user profile
 *
 * @param data      data, provided to perform the operation
 */
export async function updateProfile(
  data: UpdateProfileRequest
): Promise<UpdateProfileResult> {
  return await fetchData<
    UpdateProfileRequest,
    UpdateProfileResponse,
    ResponseDTO
  >(operations.updateProfile, data, () => {
    refreshCurrentUserData()
  })
}

/**
 * create a user
 *
 * @param data      data, provided to perform the operation
 */
export async function createUser(
  data: CreateUserRequest
): Promise<FetchDataResult<ResponseDTO>> {
  return await fetchData<CreateUserRequest, ResponseDTO, ErrorResponseDTO>(
    operations.createUser,
    data
  )
}

/**
 * request the list of users
 *
 * @param data      data to be used for result filtering
 */
export async function listUsers(
  data: ListUsersRequest
): Promise<FetchDataResult<Array<User>>> {
  return await fetchData<ListUsersRequest, Array<User>, ListUsersResponse>(
    operations.listUsers,
    data
  )
}

/**
 * update user
 *
 * @param data      data, provided to perform the operation
 */
export async function updateUser(
  data: UpdateUserRequest
): Promise<FetchDataResult<ResponseDTO>> {
  return await fetchData<UpdateUserRequest, ResponseDTO, ErrorResponseDTO>(
    operations.updateUser,
    data,
    () => {
      // refreshCurrentUserData()
    }
  )
}

/**
 * user data object
 */
export interface UserData {
  clientId?: number
  userName: string
  displayName?: string
  emailAddress?: string
  password?: string
  permissions?: PermissionMap
}

/**
 * create default user permission object
 */
export const createDefaultPermissions = (): PermissionMap => ({
  admin: false,
  readOnly: false,
  accounting: false,
})

/**
 * create default user data object
 */
export function createDefaultUserData(): UserData {
  return {
    clientId: -1,
    userName: "",
    displayName: "",
    emailAddress: "",
    password: "",
    permissions: createDefaultPermissions(),
  }
}

/**
 * delete a user by id
 *
 * @param data      data to be used for result filtering
 */
export async function deleteUser(
  data: DeleteUserRequest
): Promise<FetchDataResult<DeleteUserResponse>> {
  return await fetchData<
    DeleteUserRequest,
    DeleteUserResponse,
    ErrorResponseDTO
  >(operations.deleteUser, data)
}
