import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios"

import Configuration from "@/configuration"

import { getJwt, isAuthenticated } from "@/_store/user"

import { ErrorResponseDTO, ResponseDTO } from "./api-docs"
import { authorizationHeaderFromJwt, logout } from "./user"

export type ErrorResponse = ErrorResponseDTO
export type Response = ResponseDTO

/**
 * base URL for HTTP requests
 */
export const END_POINT_BASE_URL =
  Configuration.value("apiBaseUrl") || "http://localhost:8080/sardana/"

export interface FetchOperation {
  method: Method
  url: string
  isJson?: boolean
}

export type FetchCallback<T> = (data: T, response?: AxiosResponse<T>) => void

/**
 * API call result
 */
export interface FetchDataResult<T> {
  /**
   * logical request result
   */
  success: boolean
  /**
   * data, provided by API call response
   */
  data?: T | Response
  /**
   * error message, which is populated if some exception occur
   */
  errorMessage?: string
}

/**
 * go through the data values and change all empty strings to `null`
 *
 * @param data      data as JSON object
 */
// eslint-disable-next-line
function emptyStringToNull(data: any): void {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "") {
        data[key] = null
      } else {
        emptyStringToNull(data[key])
      }
    })
  } else {
    if (Array.isArray(data)) {
      data.forEach((it, idx) => {
        if (it === "") {
          data[idx] = null
        }
      })
    }
  }

  return data
}

/**
 * recursively extend the `formData` by given JSON `data`
 *
 * @param formData      form data to be extended
 * @param data          JSON data
 * @param parentKey     prefix for form data keys
 */
// eslint-disable-next-line
function buildFormData(formData: FormData, data: any, parentKey = ""): void {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      )
    })
  } else {
    const value = data == null ? "" : data

    // TODO, FIXME: empty string values will not be submitted;
    //  this is the conversion of empty string to `null`
    if (value !== "") {
      formData.append(parentKey, value)
    }
  }
}

/**
 * convert given JSON data to form data
 * @param data
 */
// eslint-disable-next-line
function jsonToFormData(data: any): FormData {
  const formData = new FormData()

  buildFormData(formData, data)

  return formData
}
// eslint-disable-next-line
export function encodeDataToQueryString(data: any): string {
  const formData = jsonToFormData(data)

  const entries = [...formData.entries()]
  return entries
    .map(
      (x) =>
        `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1].toString())}`
    )
    .join("&")
}

function isJwtExpired(responseData: Response | undefined): boolean {
  return !!responseData?.message?.startsWith("JWT expired")
}

/**
 * perform HTTP API request and return data from response
 *
 * @param op            URL path suffix, identifying the operation, which need
 *                      to be performed
 * @param requestData   data, which will be send to backend
 * @param cb            callback function to be executed if request succeeded
 * @param cbError       callback function to be executed if request failed
 * @param cbFinal       callback function to be finally executed
 */
export async function fetchData<I, O, E>(
  op: FetchOperation,
  requestData?: I,
  cb?: FetchCallback<O>,
  cbError: FetchCallback<E> = (data, response) => {
    console.warn("Error on fetch is occur; received data", { data, response })
  },
  cbFinal?: (result: FetchDataResult<O>) => void
): Promise<FetchDataResult<O>> {
  const result: FetchDataResult<O> = {
    success: false,
    data: undefined,
  }
  try {
    const requestConfig: AxiosRequestConfig = {
      baseURL: END_POINT_BASE_URL,
      headers: [],
      withCredentials: true,
      ...op,
      ...(op.method === "get" && requestData
        ? {
            params: requestData,
          }
        : {}),
      data: op.isJson
        ? emptyStringToNull(requestData)
        : jsonToFormData(requestData),
    }

    if (isAuthenticated()) {
      requestConfig.headers = {
        Authorization: authorizationHeaderFromJwt(getJwt()),
      }
    }

    const http = axios.create()

    const response = await http.request(requestConfig)
    const responseData = response.data

    if (typeof cb === "function") {
      cb(responseData, response)
    }

    result.success = true
    result.data = responseData
  } catch (e) {
    if (typeof cbError === "function") {
      cbError(e.response?.data, e.response)
    }

    result.success = false
    result.data = e.response?.data
    result.errorMessage = e.message

    if (isJwtExpired(result.data)) {
      logout()
    }
  }

  if (typeof cbFinal === "function") {
    cbFinal(result)
  }

  return result
}
