import { Client, getClientList } from "@/_service/client"
import { createCacheStore } from "@/_store/storeHelper"

const store = createCacheStore<Client>(getClientList)

export const clear = store.clear
export const getClients = store.getCached
export const setClients = store.setCached
export const loadClients = store.loadCached
export const smartGetClients = store.smartGetCached
export const useClients = store.useCached
