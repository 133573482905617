import Vue from "vue"
import VueI18n from "vue-i18n"
import { Ref, watch } from "@vue/composition-api"

import type { UseI18n } from "./types"
import { availableLocales } from "@/_i18n/index"

/**
 * the local storage key, used to store the i18n locale
 */
export const LS_I18N_LOCALE_KEY = "i18n_locale"

/**
 * Provide i18n functionality
 *
 * @param locale      global i18n locale
 * @param options     initialization options for vue-i18n
 */
export function createI18n(
  locale: Ref<string>,
  options?: VueI18n.I18nOptions
): UseI18n {
  Vue.use(VueI18n)

  // FIXME: this is a workaround preparation for manual merge of shared messages
  // Actually (https://kazupon.github.io/vue-i18n/guide/component.html#shared-locale-messages-for-components),
  // ```
  // If sharedMessages option is specified along with the messages option,
  // those messages will be merged into locale messages into the VueI18n
  // instance of the target component.
  // ```
  let overrideMessages: VueI18n.LocaleMessages | undefined
  if (options?.sharedMessages) {
    if (options.messages) {
      overrideMessages = options.messages
    }
    // this "copy" is necessary to prevent changes on shared messages through
    // the merge (see below)
    options.messages = { ...options.sharedMessages }
  }

  const i18n = new VueI18n(options)

  // FIXME: this is a workaround, which manually merges shared messages.
  if (overrideMessages) {
    availableLocales.forEach((l) => {
      const localeMessages = overrideMessages?.[l]
      if (localeMessages) i18n.mergeLocaleMessage(l, localeMessages)
    })
  }

  const vm = new Vue({
    i18n,
  })

  watch(locale, () => {
    i18n.locale = locale.value
    localStorage.setItem(LS_I18N_LOCALE_KEY, locale.value)
  })

  return () => ({
    locale,
    t: vm.$t.bind(vm),
    tc: vm.$tc.bind(vm),
    d: vm.$d.bind(vm),
    te: vm.$te.bind(vm),
  })
}
